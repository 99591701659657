<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PropertyApiExecutionTime') }}</h3>
          </div>
          <div v-if="fetchingPropertyApiTimes" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              legend: { display: false },
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function(value, index) {
                      return formattedDate( value, index );
                    }
                  }
                }]
              }
            }" 
            :chart-data="propertyApiTimes"></line-chart>
          <p v-if="!fetchingPropertyApiTimes && propertyApiTimes.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.CollectionsApiExecutionTime') }}</h3>
          </div>
          <div v-if="fetchingCollectionsApiTimes" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              legend: { display: false },
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function(value, index) {
                      return formattedDate( value, index );
                    }
                  }
                }]
              }
            }" 
            :chart-data="collectionsApiTimes"></line-chart>
          <p v-if="!fetchingCollectionsApiTimes && collectionsApiTimes.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.ContentApiExecutionTime') }}</h3>
          </div>
          <div v-if="fetchingContentApiTimes" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              legend: { display: false },
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function(value, index) {
                      return formattedDate( value, index );
                    }
                  }
                }]
              }
            }" 
            :chart-data="contentApiTimes"></line-chart>
          <p v-if="!fetchingContentApiTimes && contentApiTimes.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.ApplicationApiExecutionTime') }}</h3>
          </div>
          <div v-if="fetchingApplicationApiTimes" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              legend: { display: false },
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function(value, index) {
                      return formattedDate( value, index );
                    }
                  }
                }]
              }
            }" 
            :chart-data="applicationApiTimes"></line-chart>
          <p v-if="!fetchingApplicationApiTimes && applicationApiTimes.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import { mapState } from 'vuex';
import moment from 'moment';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import { ApiResponseTimesFilterType } from '@/utils/defaultValues';

export default {
  name: 'ApiExecutionTimeTab',
  components: {
    LineChart
  },
  data () {
    return {
      chartOptions: chartsConfig.options,
      fetchingPropertyApiTimes: true,
      fetchingCollectionsApiTimes: true,
      fetchingContentApiTimes: true,
      fetchingApplicationApiTimes: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analytics) ? state.venom.org.analytics : {}
    }),
    propertyApiTimes() {
      const labels = [];
      const values = [];
      this.analytics?.propertyApiResponseTimes.map( d => {
        labels.push( d.createdAt );
        values.push( d.responseTime );
      });
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    },
    collectionsApiTimes() {
      const labels = [];
      const values = [];
      this.analytics?.collectionsApiResponseTimes.map( d => {
        labels.push( d.createdAt );
        values.push( d.responseTime );
      });
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    },
    contentApiTimes() {
      const labels = [];
      const values = [];
      this.analytics?.contentApiResponseTimes.map( d => {
        labels.push( d.createdAt );
        values.push( d.responseTime );
      });
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    },
    applicationApiTimes() {
      const labels = [];
      const values = [];
      this.analytics?.applicationsApiResponseTimes.map( d => {
        labels.push( d.createdAt );
        values.push( d.responseTime );
      });
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    }
  },
  methods: {
    formattedDate( date, index ) {
      if ( index % 10 == 0 ) {
        return moment( date ).format('hh:mm A');
      } else {
        return '';
      }
    },
    async fetchPropertyApiTimes() {
      this.fetchingPropertyApiTimes = true;
      await this.$store.dispatch('analyticsApiResponseTimes', { 
        orgId: this.orgId,
        apiType: ApiResponseTimesFilterType.propertyApi,
        apiKey: 'property'
      });
      this.fetchingPropertyApiTimes = false;
    },
    async fetchCollectionsApiTimes() {
      this.fetchingCollectionsApiTimes = true;
      await this.$store.dispatch('analyticsApiResponseTimes', { 
        orgId: this.orgId,
        apiType: ApiResponseTimesFilterType.collectionsApi,
        apiKey: 'collections'
      });
      this.fetchingCollectionsApiTimes = false;
    },
    async fetchContentApiTimes() {
      this.fetchingContentApiTimes = true;
      await this.$store.dispatch('analyticsApiResponseTimes', { 
        orgId: this.orgId,
        apiType: ApiResponseTimesFilterType.contentApi,
        apiKey: 'content'
      });
      this.fetchingContentApiTimes = false;
    },
    async fetchApplicationsApiTimes() {
      this.fetchingApplicationApiTimes = true;
      await this.$store.dispatch('analyticsApiResponseTimes', { 
        orgId: this.orgId,
        apiType: ApiResponseTimesFilterType.applicationsApi,
        apiKey: 'applications'
      });
      this.fetchingApplicationApiTimes = false;
    }
  },
  mounted() {
    this.fetchPropertyApiTimes();
    this.fetchCollectionsApiTimes();
    this.fetchContentApiTimes();
    this.fetchApplicationsApiTimes();
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>