<template>
  <div>
    <div uk-grid>
      <div class="uk-width-1-4@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.DataStorage') }}</h3>
          </div>
          <div v-if="fetchingUsagesData" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <h2 v-else class="uk-position-center">{{ dataStorage }}</h2>
        </div>
      </div>
      <div class="uk-width-expand@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.BandwidthUsage') }}</h3>
          </div>
          <div v-if="fetchingUsagesData" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              legend: { display: false }
            }" 
            :chart-data="usagesData"></line-chart>
          <p v-if="!fetchingUsagesData && usagesData.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import { mapState } from 'vuex';
import moment from 'moment';
import { chartsConfig, getLabelOptions } from '@/utils/charts';

export default {
  name: 'UsagesTab',
  components: {
    LineChart
  },
  props: {
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null }
  },
  data () {
    return {
      chartOptions: chartsConfig.options,
      fetchingUsagesData: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analyticsUsagesTab) ? state.venom.org.analyticsUsagesTab : {}
    }),
    dataStorage() {
      const size = this.analytics.dataUsage.value;
      return this.$options.filters.appropriateBytes( size );
    },
    usagesData() {
      const labels = Object.keys( this.analytics?.bandwidthUsage?.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const values = Object.values( this.analytics?.bandwidthUsage?.value ).reverse();
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    }
  },
  methods: {
    async fetchUsagesData() {
      this.fetchingUsagesData = true;
      const resp = await this.$store.dispatch('analyticsUsagesData', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        ref: 'usages-tab'
      });
      if ( resp.organization?.analytics?.bandwidthUsage.stale || resp.organization?.analytics?.dataUsage.stale ) {
        this.fetchUsagesData();
      } else {
        this.fetchingUsagesData = false;
      }
    }
  },
  mounted() {
    this.fetchUsagesData();
  },
  watch: {
    startDate () {
      this.fetchUsagesData();
    },
    endDate () {
      this.fetchUsagesData();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>