<template>
  <div>
    <div class="uk-flex uk-width-1-1 uk-flex-between">
      <div>
        <h1 class="uk-text-left uk-margin-remove-bottom">{{ $t('Sidebar.Analytics') }}</h1>
      </div>
    </div>
    <hr />
    <ul uk-tab id='app-edit-tabs'>
      <li :class="selectedTabClass.overview" @click="selectTab('overview')"><a href="">{{ $t('Pages.Analytics.Overview') }}</a></li>
      <li :class="selectedTabClass.visitors" @click="selectTab('visitors')"><a href="">{{ $t('Pages.Analytics.VisitorsData') }}</a></li>
      <li :class="selectedTabClass.time" @click="selectTab('time')"><a href="">{{ $t('Pages.Analytics.TimeData') }}</a></li>
      <li :class="selectedTabClass.platform" @click="selectTab('platform')"><a href="">{{ $t('Pages.Analytics.Platform') }}</a></li>
      <li :class="selectedTabClass.publishing" @click="selectTab('publishing')"><a href="">{{ $t('Pages.Analytics.Publishing') }}</a></li>
      <li :class="selectedTabClass.collections" @click="selectTab('collections')"><a href="">{{ $t('Pages.Analytics.Collections') }}</a></li>
      <li :class="selectedTabClass.vod" @click="selectTab('vod')"><a href="">{{ $t('Pages.Analytics.VODData') }}</a></li>
      <li :class="selectedTabClass.liveStreams" @click="selectTab('liveStreams')"><a href="">{{ $t('Pages.Analytics.LiveStreams') }}</a></li>
      <li :class="selectedTabClass.usages" @click="selectTab('usages')"><a href="">{{ $t('Pages.Analytics.Usages') }}</a></li>
      <li v-if="isOrangeOrg" :class="selectedTabClass.apiExecutionTime" @click="selectTab('apiExecutionTime')"><a href="">{{ $t('Pages.Analytics.APIExecutionTime') }}</a></li>
      <li v-if="isDTOrg" :class="selectedTabClass.xrEcosystem" @click="selectTab('xrEcosystem')"><a href="">{{ $t('Pages.Analytics.XREcosystem') }}</a></li>
    </ul>
    <div uk-grid>
    <div class="uk-text-left uk-width-expand uk-flex">
      <div class="uk-margin-right uk-margin-remove-bottom uk-margin-remove-left">
        <InputDatePicker
            style="width:400px"
            id="startAndEndDate"
            formatted="MMM, DD, YYYY"
            :label="$t('Pages.Content.Availability.SelectDateRange')"
            with-shortcuts
            range
            :start="startDate"
            :end="endDate"
            :max-date="maxDate"
            @input="selectedDateRange"
      />
      </div>
       <div class="uk-margin-right uk-margin-remove-bottom uk-margin-remove-left">
        <div class="uk-text-left uk-text-meta uk-margin-small-top">{{$t('Pages.Analytics.Title.Grouping') }}</div>
        <select class="uk-select uk-margin-small" style="width: 120px;" v-model="grouping">
          <option
          v-for="(group, i) in dateGrouping"
          :key="i"
          :value="group">
          {{ $t( `enums.DateGrouping.${group}` ) }}
          </option>
        </select>
      </div>
      <div v-if="selectedTab !=='vod'" class="uk-margin-right uk-width-small uk-margin-remove-bottom uk-margin-remove-left">
        <div class="uk-text-left uk-text-meta uk-margin-small-top">{{$t('Pages.Analytics.Title.Platforms') }}</div>
        <select class="uk-select uk-margin-small" v-model="platform">
          <option
          v-for="(platform, i) in allPlatforms"
          :key="i"
          :value="platform">
          {{ platform }}
          </option>
        </select>
      </div>
      <div class="uk-margin-right uk-width-small uk-margin-remove-bottom uk-margin-remove-left">
        <div class="uk-text-left uk-text-meta uk-margin-small-top">{{$t('Pages.Analytics.Title.TopCountries') }}</div>
        <select class="uk-select uk-margin-small" v-model="location">
          <option
          v-for="(platform, i) in topCountries"
          :key="i"
          :value="platform">
          {{ platform }}
          </option>
        </select>
      </div>
      <div class="uk-margin-right uk-margin-remove-bottom uk-margin-remove-left">
        <div class="uk-text-meta uk-margin-small-top uk-text-left">{{$t('Pages.Analytics.Title.Rows') }}</div>
        <input class="uk-input uk-margin-right uk-width-small uk-margin-small-top" style="width: 75px;" type="number" v-model="count" />
      </div>
      </div>
       <div class="uk-margin-right uk-float-right uk-margin-medium-top uk-margin-remove-bottom uk-margin-remove-left">
        <a class="export uk-button uk-button-primary uk-margin-remove-bottom" :href="exportFullUrl" download>
          <span class="uk-margin-small-right uk-padding-remove" uk-icon="cloud-download"></span> {{$t('Pages.Analytics.DownloadReport')}}
        </a>
      </div>
    </div>
      
    <ul class="uk-switcher uk-margin uk-margin-medium-top">
      <li>
        <div class="uk-child-width-1-2@s" uk-grid>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.UniqueActiveVisitors') }}</h3>
              </div>
              <div v-if="fetchingUniqueAndActiveUsers" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <line-chart v-else class="custom-chart" :height="230" :options="chartConfig" :chart-data="uniqeAndActiveUsersData"></line-chart>
              <p v-if="!fetchingUniqueAndActiveUsers && uniqeAndActiveUsersData.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.AvgViewAppTime') }}</h3>
              </div>
              <div v-if="fetchingViewAndAppTime" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <bar-chart v-else class="custom-chart" :height="230" :options="chartConfig" :chart-data="viewAndAppTimeData"></bar-chart>
              <p v-if="!fetchingViewAndAppTime && viewAndAppTimeData.labels.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
        </div>
        <div class="uk-child-width-1-2@s" uk-grid>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularPlatforms') }}</h3>
              </div>
              <div v-if="fetchingPopularPlatforms" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <bar-chart v-else class="custom-chart" :height="230" 
                :options="{
                  ...chartConfigCustom,
                  legend: { display: false }
                }"
                :chart-data="popularPlatforms"></bar-chart>
              <p v-if="!fetchingPopularPlatforms && popularPlatforms.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularCategories') }}</h3>
              </div>
              <div v-if="fetchingPopularCategories" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <bar-chart v-else class="custom-chart" :height="230" 
                :options="{
                  ...chartConfigCustom,
                  legend: { display: false },
                  scales: {
                    xAxes: [{
                      ticks: {
                        callback: function(value) {
                          return getAbbrv(value);
                        }
                      }
                    }]
                  }
                }" 
                :chart-data="popularCategories"></bar-chart>
              <p v-if="!fetchingPopularCategories && popularCategories.labels.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularCollections') }}</h3>
              </div>
              <div v-if="fetchingPopularCollections" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <bar-chart v-else class="custom-chart" :height="230" 
                :options="{
                  ...chartConfigCustom,
                  legend: { display: false },
                  scales: {
                    xAxes: [{
                      ticks: {
                        callback: function(value) {
                          return getAbbrv(value);
                        }
                      }
                    }]
                  }
                }" 
                :chart-data="popularCollections"></bar-chart>
              <p v-if="!fetchingPopularCollections && popularCollections.labels.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.VideoDownloads') }}</h3>
              </div>
              <div v-if="fetchingVideoDownloads" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <line-chart v-else class="custom-chart" :height="230" 
                :options="{
                  ...chartConfigCustom,
                  legend: { display: false }
                }"
                :chart-data="videoDownloads"></line-chart>
              <p v-if="!fetchingVideoDownloads && videoDownloads.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
        </div>
        <div class="uk-child-width-1-1@s" uk-grid>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularUniqueViews', { count }) }}</h3>
              </div>
              <div v-if="fetchingVodUniqueViews" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <bar-chart v-else class="custom-chart" :height="230" 
                :options="{
                  ...chartConfig,
                  scales: {
                    xAxes: [{
                      ticks: {
                        callback: function(value) {
                          return getAbbrv(value);
                        }
                      }
                    }]
                  }
                }" 
                :chart-data="vodUniqueViews"></bar-chart>
              <p v-if="!fetchingVodUniqueViews && vodUniqueViews.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularCompletedVideos', { count } ) }}</h3>
              </div>
              <div v-if="fetchingUniqueCompletedVods" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <div v-else class="uk-overflow-auto" style="height: 355px">
                <base-table :tableHeaders="uniqueCompletedVodHeaders">
                  <tr v-if="!fetchingUniqueCompletedVods && uniqueCompletedVods.length == 0">
                    <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
                  </tr>
                  <tr 
                    v-else
                    v-for="(vod, i) in uniqueCompletedVods"
                    :key="i"
                  >
                    <td>{{ vod.title }}</td>
                    <td class="uk-text-center">{{ vod.unique_views }}</td>
                    <td class="uk-text-center">{{ Math.ceil(vod.avg_percent_completion * 100) }}%</td>
                    <td class="uk-text-center">{{ vod.total_views }}</td>
                  </tr>
                </base-table>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-child-width-1-2@s" uk-grid>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LiveStreams') }}</h3>
              </div>
              <div v-if="fetchingLiveStreams" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <line-chart v-else class="custom-chart" :height="230" 
                :options="{
                  ...chartConfigCustom,
                  legend: { display: false }
                }"
                :chart-data="liveStreams"></line-chart>
              <p v-if="!fetchingLiveStreams && liveStreams.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
            </div>
          </div>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
              <div class="uk-flex">
                <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.StoriesHotspots') }}</h3>
              </div>
              <div v-if="fetchingTopStories" class="uk-position-center">
                <span uk-spinner></span>
              </div>
              <div v-else class="uk-overflow-auto" style="height: 355px">
                <base-table :tableHeaders="topStoriesHeaders">
                  <tr v-if="!fetchingTopStories && topStories.length == 0">
                    <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
                  </tr>
                  <tr 
                    v-else
                    v-for="(story, i) in topStories"
                    :key="i"
                  >
                    <td>{{ story.title }}</td>
                    <td class="uk-text-center">{{ story.unique_views }}</td>
                    <td class="uk-text-center">{{ Math.ceil(story.avg_percent_completion * 100) }}%</td>
                    <td class="uk-text-center">{{ story.total_views }}</td>
                  </tr>
                </base-table>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <visitors-data-tab :startDate="startDate" :endDate="endDate" :platform="platform" :location="location" :grouping="grouping" />
      </li>
      <li>
        <time-data-tab :startDate="startDate" :endDate="endDate" :platform="platform" :location="location" :grouping="grouping" />
      </li>
      <li>
        <platforms-tab :startDate="startDate" :endDate="endDate" />
      </li>
      <li>
        <publishing-tab :count="count" :startDate="startDate" :endDate="endDate" :platform="platform" :location="location" />
      </li>
      <li>
        <collections-tab :startDate="startDate" :endDate="endDate" :platform="platform" :location="location" :grouping="grouping" />
      </li>
      <li>
        <vod-data-tab :count="count" :startDate="startDate" :endDate="endDate" :platform="platform" :location="location" :grouping="grouping" />
      </li>
      <li>
        <live-streams-tab :count="count" :startDate="startDate" :endDate="endDate" :platform="platform" :location="location" :grouping="grouping" />
      </li>
      <li>
        <usages-tab :startDate="startDate" :endDate="endDate" />
      </li>
      <li v-if="isOrangeOrg">
        <api-execution-time-tab />
      </li>
      <li v-if="isDTOrg">
        <xr-ecosystem-tab />
      </li>
    </ul>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import BarChart from '@/components/charts/BarChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import { getTwoCharacterAbbreviation } from '@/components/util/Strings';
import VisitorsDataTab from './analytics/VisitorsDataTab';
import TimeDataTab from './analytics/TimeDataTab';
import PlatformsTab from './analytics/PlatformsTab';
import PublishingTab from './analytics/PublishingTab';
import CollectionsTab from './analytics/CollectionsTab';
import VodDataTab from './analytics/VodDataTab';
import LiveStreamsTab from './analytics/LiveStreamsTab';
import UsagesTab from './analytics/UsagesTab';
import { orangeId, dtId } from '@/utils/defaultValues';
import XrEcosystemTab from './analytics/XrEcosystemTab';
import ApiExecutionTimeTab from './analytics/ApiExecutionTimeTab';
import { backend } from '@/app.config';
import InputDatePicker from '@/components/InputDatePicker';

export default {
  name: 'AnalyticsView',
  components: {
    LineChart,
    BarChart,
    BaseTable,
    VisitorsDataTab,
    TimeDataTab,
    PlatformsTab,
    PublishingTab,
    CollectionsTab,
    VodDataTab,
    LiveStreamsTab,
    UsagesTab,
    XrEcosystemTab,
    ApiExecutionTimeTab,
    InputDatePicker
  },
  data () {
    return {
      orgId: this.$route.params.id,
      selectedTab: this.$route.query?.tab || 'overview',
      selectedTabClass: {
        overview: this.$route.query?.tab === 'overview' ? 'uk-active uk-text-break' : 'uk-text-break',
        visitors: this.$route.query?.tab === 'visitors' ? 'uk-active uk-text-break' : 'uk-text-break',
        time: this.$route.query?.tab === 'time' ? 'uk-active uk-text-break' : 'uk-text-break',
        platform: this.$route.query?.tab === 'platform' ? 'uk-active uk-text-break' : 'uk-text-break',
        publishing: this.$route.query?.tab === 'publishing' ? 'uk-active uk-text-break' : 'uk-text-break',
        collections: this.$route.query?.tab === 'collections' ? 'uk-active uk-text-break' : 'uk-text-break',
        vod: this.$route.query?.tab === 'vod' ? 'uk-active uk-text-break' : 'uk-text-break',
        liveStreams: this.$route.query?.tab === 'liveStreams' ? 'uk-active uk-text-break' : 'uk-text-break',
        usages: this.$route.query?.tab === 'usages' ? 'uk-active uk-text-break' : 'uk-text-break',
        apiExecutionTime: this.$route.query?.tab === 'apiExecutionTime' ? 'uk-active uk-text-break' : 'uk-text-break',
        xrEcosystem: this.$route.query?.tab === 'xrEcosystem' ? 'uk-active uk-text-break' : 'uk-text-break'
      },
      uniqueCompletedVodHeaders: [{
        name: this.$t( 'Pages.Analytics.Table.VideoTitle' ), 
        class: 'uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgPercentCompletion' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ),
        class: 'uk-text-center uk-text-middle'
      }],
      topStoriesHeaders: [{
        name: this.$t( 'Pages.Analytics.Table.VideoTitle' ), 
        class: 'uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgPercentCompletion' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ),
        class: 'uk-text-center uk-text-middle'
      }],
      chartConfig: {
        legend: {
          position: 'top',
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        }
      },
      chartConfigCustom: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        }
      },
      grouping: 'MONTHLY',
      startDate: moment().subtract(6, 'months'),
      endDate: moment(),
      location: 'Global',
      platform: 'All',
      count: 10,
      fetchingUniqueAndActiveUsers: true,
      fetchingViewAndAppTime: true,
      fetchingPopularPlatforms: true,
      fetchingPopularCategories: true,
      fetchingPopularCollections: true,
      fetchingVodUniqueViews: true,
      fetchingUniqueCompletedVods: true,
      fetchingLiveStreams: true,
      fetchingTopStories: true,
      fetchingVideoDownloads: true,
      allPlatforms: ['All'],
      topCountries: ['Global'],
      dateGrouping: ['NONE', 'DAILY', 'WEEKLY', 'MONTHLY', 'QUARTERLY' ]
    }
  },
  computed: {
    ...mapState({
      orgName: state => state.venom.org?.name || '',
      analytics: state => (state.venom.org.analytics) ? state.venom.org.analytics : {}
    }),
    isOrangeOrg() {
      return this.orgId === orangeId;
    },
    isDTOrg() {
      return this.orgId === dtId;
    },
    getMonths() {
      const months = [];
      for ( let i = 0; i <= 6; i++ ) {
        months.push( moment().subtract(i, 'months').format('MMM\' YY') );
      }
      return months.reverse();
    },
    totalUniqueVisitors() {
      const labels = Object.keys( this.analytics.totalUniqueVisitors.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const values = Object.values( this.analytics.totalUniqueVisitors.value ).reverse();
      return {
        labels: labels,
        datasets: [{
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          data: values
        }]
      };
    },
    monthlyActiveUsers() {
      const labels = Object.keys( this.analytics.activeUsers.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const values = Object.values( this.analytics.activeUsers.value ).reverse();
      return {
        labels: labels,
        datasets: [{
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          data: values
        }]
      };
    },
    uniqeAndActiveUsersData() {
      const labels = Object.keys( this.analytics?.totalUniqueVisitors?.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const uniqueVisitors = Object.values( this.analytics?.totalUniqueVisitors?.value ).reverse();
      const activeUsers = Object.values( this.analytics?.activeUsers?.value ).reverse();

      return {
        labels: labels,
        datasets: [{
          label: this.$t( 'Pages.Analytics.Title.UniqueVisitors' ),
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          data: uniqueVisitors
        }, {
          label: this.$t( 'Pages.Analytics.Title.ActiveUsers' ),
          borderWidth: 1,
          borderColor: '#8d00ff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#8d00ff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: '#aa00ff20',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          data: activeUsers
        }]
      };
    },
    uniqeAndActiveUsersDataTable() {
      return {
        uniqueVisitors: this.analytics.totalUniqueVisitors,
        activeUsers: this.analytics.activeUsers
      };
    },
    viewAndAppTimeData() {
      const labels = Object.keys( this.analytics.viewTime.value );
      const viewTime = Object.values( this.analytics.viewTime.value );
      const appTime = Object.values( this.analytics.sessionDuration.value );

      return {
        labels: labels,
        datasets: [{
          label: this.$t( 'Pages.Analytics.Title.AvgViewTime' ),
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          barThickness: 20,
          data: viewTime
        }, {
          label: this.$t( 'Pages.Analytics.Title.AppTime' ),
          borderWidth: 1,
          borderColor: '#8d00ff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#8d00ff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: '#aa00ff20',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          barThickness: 20,
          data: appTime
        }]
      };
    },
    popularPlatforms() {
      const labels = Object.keys( this.analytics.forAllPlatforms.value );
      let values = Object.values( this.analytics.forAllPlatforms.value );

      let datasets = [{
        borderWidth: 1,
        borderColor: '#00aaff',
        pointRadius: 4,
        pointBackgroundColor: '#cacaca',
        pointBorderColor: '#00aaff',
        pointBorderWidth: 4,
        pointHoverBorderWidth: 15,
        backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
        fill: 'bottom',
        lineTension: 0.25,
        spanGaps: true,
        barThickness: 20,
        data: values
      }];
      const totalBlank = values.filter(v => v === 0 );
      if ( values.length === totalBlank.length ) {
        datasets = [];
      }
      return {
        labels: labels,
        datasets: datasets
      };
    },
    popularCategories() {
      let labels = [];
      let values = [];
      this.analytics.topProperties.value.map( p => {
        labels.push( p.name );
        values.push( p.play_count );
      });
      return {
        labels: labels,
        datasets: [{
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          barThickness: 20,
          data: values
        }]
      };
    },
    popularCollections() {
      let labels = [];
      let values = [];
      if ( this.analytics?.topCollections ) {
        this.analytics.topCollections.value.map( p => {
          labels.push( p.name );
          values.push( p.play_count );
        });
      }
      
      return {
        labels: labels,
        datasets: [{
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          barThickness: 20,
          data: values
        }]
      };
    },
    vodUniqueViews() {
      let platforms = [];
      Object.values( this.analytics.topVodsByUnique.value ).map( p => 
        platforms = new Set([ ...platforms, ...Object.keys( p.views_per_platform ) ])
      );

      const labels = [];
      let labelValues = {};
      Object.values( this.analytics.topVodsByUnique.value ).map( p => {
        labels.push( p.title );
        
        // prepare set of values for each platform
        [ ...platforms ].map( name => {
          const val = p.views_per_platform[name]?.unique || 0;
          labelValues[name] ? labelValues[name].push( val ) : labelValues[name] = [ val ];
        });
      });
      
      // prepare dataset for all the values to be plotted on the chart
      const datasets = [];
      Object.keys( labelValues ).map( (name, key) => {
        datasets.push({
          label: name,
          borderWidth: 1,
          borderColor: chartsConfig.borderColor[key],
          pointRadius: 4,
          pointBackgroundColor: chartsConfig.pointBackgroundColor[key],
          pointBorderColor: chartsConfig.pointBorderColor[key],
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: chartsConfig.backgroundColor[key],
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          barThickness: 20,
          data: labelValues[name]
        });
      });
      
      return {
        labels: labels,
        datasets: datasets
      };
    },
    uniqueCompletedVods() {
      return this.analytics?.topVodsByCompletion?.value || [];
    },
    liveStreams() {
      const labels = Object.keys( this.analytics.liveStreams.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const values = Object.values( this.analytics.liveStreams.value ).reverse();
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    },
    topStories() {
      return this.analytics?.topStories?.value || [];
    },
    videoDownloads() {
      const labels = Object.keys( this.analytics.downloadCount.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const values = Object.values( this.analytics.downloadCount.value ).reverse();
      return {
        labels: labels,
        datasets: [{
          label: this.$t( 'Pages.Analytics.Title.DownloadCount' ),
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          data: values
        }]
      };
    },
    topVodsByUniqueHeader() {
      const headers = [{
        name: this.$t( 'Pages.Analytics.Table.VideoTitle' ), 
        class: 'uk-width-large uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgTimeViewed' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalTimeViewed' ), 
        class: 'uk-text-center uk-text-middle'
      }];
      return headers;
    },
    topVodsByUnique() {
      const vods = this.analytics.topVodsByUnique;
      return vods.value;
    },
    uniqueViews() {
      const labels = Object.keys( this.analytics.uniqueViews.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const uniqueViews = Object.values( this.analytics.uniqueViews.value ).reverse();

      return {
        labels: labels,
        datasets: [{
          label: this.$t( 'Pages.Analytics.Title.UniqueViews' ),
          borderWidth: 1,
          borderColor: '#00aaff',
          pointRadius: 4,
          pointBackgroundColor: '#cacaca',
          pointBorderColor: '#00aaff',
          pointBorderWidth: 4,
          pointHoverBorderWidth: 15,
          backgroundColor: 'rgba( 40, 80, 255, 0.2 )',
          fill: 'bottom',
          lineTension: 0.25,
          spanGaps: true,
          data: uniqueViews
        }]
      };
    },
    exportFullUrl () {
      return `${backend.analytics_export_url}/export?orgId=${this.$route.params.id}&orgName=${this.orgName}&${this.exportStartDate}&${this.exportEndDate}`;
    },
    exportStartDate () {
      return `&startDate=${( !this.startDate ) ? moment().subtract( 6, 'months' ).startOf( 'month' ).toISOString() : moment( this.startDate ).toISOString()}`;
    },
    exportEndDate () {
      return `&endDate=${( !this.endDate ) ? moment().toISOString() : moment( this.endDate ).toISOString()}`;
    },
    maxDate () {
      return moment().format( 'MMM DD, YYYY' );
    }
  },
  methods: {
    selectTab( tab ) {
      this.selectedTab = tab;
    },
    getAbbrv( value ) {
      return getTwoCharacterAbbreviation( value );
    },
    async fetchTopCountries() {
      const resp = await this.$store.dispatch('analyticsTopCountries', { 
        orgId: this.orgId
      });
      if ( resp )
        this.topCountries = [ 'Global', ...resp.organization?.analytics?.topCountries ? Object.keys( resp.organization?.analytics?.topCountries?.value ): [] ];
    },
    async fetchAllPlatforms() {
      await this.$store.dispatch('analyticsAllPlatforms', { 
        orgId: this.orgId
      });
    },
    async fetchUniqueAndActiveUsers() {
      this.fetchingUniqueAndActiveUsers = true;
      const resp = await this.$store.dispatch('analyticsUniqueAndActiveUsers', { 
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform
      });
      if ( resp?.organization?.analytics?.totalUniqueVisitors?.stale || resp?.organization?.analytics?.activeUsers?.stale ) {
        this.fetchUniqueAndActiveUsers();
      } else {
        this.fetchingUniqueAndActiveUsers = false;
      }
    },
    async fetchViewAndAppData() {
      this.fetchingViewAndAppTime = true;
      const resp = await this.$store.dispatch('analyticsAvgViewAndAppTime', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform
      });
      if ( resp?.organization?.analytics?.viewTime?.stale || resp?.organization?.analytics?.sessionDuration?.stale ) {
        this.fetchViewAndAppData();
      } else {
        this.fetchingViewAndAppTime = false;
      }
    },
    async fetchPopularPlatforms() {
      this.fetchingPopularPlatforms = true;
      const resp = await this.$store.dispatch('analyticsPopularPlatforms', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location
      });
      this.platforms = Object.keys( resp.organization?.analytics?.forAllPlatforms.value );
      this.allPlatforms =  [ 'All', ...this.platforms ];

      if ( resp.organization?.analytics?.forAllPlatforms?.stale ) {
        this.fetchPopularPlatforms();
      } else {
        this.fetchingPopularPlatforms = false;
      }
    },
    async fetchPopularCategories() {
      this.fetchingPopularCategories = true;
      const resp = await this.$store.dispatch('analyticsPopularCategories', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count
      });
      if ( resp.organization?.analytics?.topProperties?.stale ) {
        this.fetchPopularCategories();
      } else {
        this.fetchingPopularCategories = false;
      }
    },
    async fetchPopularCollections() {
      this.fetchingPopularCollections = true;
      const resp = await this.$store.dispatch('analyticsPopularCollections', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count
      });
      if ( resp.organization?.analytics?.topCollections?.stale ) {
        this.fetchPopularCollections();
      } else {
        this.fetchingPopularCollections = false;
      }
    },
    async fetchVodUniqueViews() {
      this.fetchingVodUniqueViews = true;
      const resp = await this.$store.dispatch('analyticsVodUniqueViews', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count
      });
      if ( resp.organization?.analytics?.topVodsByUnique?.stale ) {
        this.fetchVodUniqueViews();
      } else {
        this.fetchingVodUniqueViews = false;
      }
    },
    async fetchUniqueCompletedVods() {
      this.fetchingUniqueCompletedVods = true;
      const resp = await this.$store.dispatch('analyticsUniqueCompletedVods', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count
      });
      if ( resp?.organization?.analytics?.topVodsByCompletion?.stale ) {
        this.fetchUniqueCompletedVods();
      } else {
        this.fetchingUniqueCompletedVods = false;
      }
    },
    async fetchLiveStreams() {
      this.fetchingLiveStreams = true;
      const resp = await this.$store.dispatch('analyticsLiveStreams', { 
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform
      });
      if ( resp?.organization?.analytics?.liveStreams?.stale ) {
        this.fetchLiveStreams();
      } else {
        this.fetchingLiveStreams = false;
      }
    },
    async fetchTopStories() {
      this.fetchingTopStories = true;
      const resp = await this.$store.dispatch('analyticsTopStories', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count
      });
      if ( resp?.organization?.analytics?.topStories?.stale ) {
        this.fetchTopStories();
      } else {
        this.fetchingTopStories = false;
      }
    },
    async fetchVideoDownloads() {
      this.fetchingVideoDownloads = true;
      const resp = await this.$store.dispatch('analyticsVideoDownloads', {
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform
      });
      if ( resp?.organization?.analytics?.downloadCount?.stale ) {
        this.fetchVideoDownloads();
      } else {
        this.fetchingVideoDownloads = false;
      }
    },
    selectedDateRange ( range ) {
      if ( Object.keys( range ).length === 0 ) {
        this.startDate = '';
        this.endDate = '';
      }
      if ( range ) {
        this.startDate = range.start;
        this.endDate = range.end;
      }
    },
    fetchAllData() {
      this.fetchTopCountries();
      this.fetchAllPlatforms();
      this.fetchUniqueAndActiveUsers();
      this.fetchViewAndAppData();
      this.fetchPopularPlatforms();
      this.fetchPopularCategories();
      this.fetchPopularCollections();
      this.fetchVodUniqueViews();
      this.fetchUniqueCompletedVods();
      this.fetchLiveStreams();
      this.fetchTopStories();
      this.fetchVideoDownloads();
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.fetchAllData();
  },
  watch: {
    grouping () {
      this.fetchAllData();
    },
    startDate () {
      this.fetchAllData();
    },
    endDate () {
      this.fetchAllData();
    },
    location () {
      this.fetchAllData();
    },
    platform () {
      this.fetchAllData();
    },
    count () {
      this.count = parseInt(this.count);
      this.fetchAllData();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
.input-date-picker .field-input {
  height: 40px;
}
.export {
  padding: 0 20px;
}
</style>
