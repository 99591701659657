<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularCategories') }}</h3>
          </div>
          <div v-if="fetchingPopularCategories" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <bar-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions, 
              legend: { display: false },
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function(value) {
                      return getAbbrv(value);
                    }
                  }
                }]
              }
            }"
            :chart-data="popularCategories"></bar-chart>
          <p v-if="!fetchingPopularCategories && popularCategories.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LocationBasedPopularCategories') }}</h3>
          </div>
          <div v-if="fetchinglocationBasedCategories" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="locationBasedCategoryHeaders">
              <tr v-if="!fetchinglocationBasedCategories && locationBasedCategories.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(locView, i) in locationBasedCategories"
                :key="i"
              >
                <td v-for="(val, ii) in locView" 
                  :key="ii"
                  :class="ii === 0 ? '' : 'uk-text-center'"
                >{{ val }}</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import { getTwoCharacterAbbreviation } from '@/components/util/Strings';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import gql from 'graphql-tag';

export default {
  name: 'AnalyticsView',
  components: {
    BarChart,
    BaseTable
  },
  props: {
    count: { type: Number, default: 10 },
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null },
    location: { type: String, default: 'Global' },
    platform: { type: String, default: 'All' }
  },
  data () {
    return {
      chartOptions: chartsConfig.options,
      fetchingPopularCategories: true,
      fetchinglocationBasedCategories: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analyticsPublishingTab) ? state.venom.org.analyticsPublishingTab : {},
      countries: state => (state.venom.org.analytics?.topCountries?.value) ? state.venom.org.analytics.topCountries.value : {}
    }),
    topCountries() {
      return [ 'Global', ...Object.keys( this.countries ) ];
    },
    popularCategories() {
      let labels = [];
      let values = [];
      this.analytics.topProperties.value.map( p => {
        labels.push( p.name );
        values.push( p.play_count );
      });
      return {
        labels: labels,
        datasets: values.length > 0 ? [{
          ...getLabelOptions(),
          data: values
        }] : []
      };
    },
    locationBasedCategoriesQuery() {
      const locations = this.topCountries;
      let locationQueries = '';
      for ( let index = 0; index < locations.length; index++ ) {
        locationQueries = locationQueries.concat(
          `${locations[index].replace(' ', '__')}: topProperties (startDate: $startDate, endDate: $endDate, location: "${locations[index]}", platform: $platform, count: $count ) ` );
      }
      return locationQueries;
    },
    locationBasedCategoryHeaders() {
      const countries = Object.keys( this.analytics.locationBasedCategories );
      
      const headers = [{
        name: this.$t( 'Pages.Analytics.Table.Name' ), 
        class: 'uk-width-medium uk-text-middle'
      }];
      countries.map( c => headers.push({
        name: c.replace('__', ' '),
        class: 'uk-text-center uk-text-middle'
      }));

      return headers;
    },
    locationBasedCategories() {
      const categories = this.analytics.locationBasedCategories;
      const countries = Object.keys( categories );

      const properties = [];
      categories['Global'].value.map( prop => {
        const row = [ prop.name, prop.play_count ];
        countries.map( country => {
          if ( country === 'Global' ) return '';
          let nextValue = 0;
          categories[country].value.map( p => {
            if ( p.name === prop.name ) nextValue = p.play_count;
          });
          row.push(nextValue);
        });
        properties.push(row);
      } );

      return properties;
    }
  },
  methods: {
    getAbbrv( value ) {
      return getTwoCharacterAbbreviation( value );
    },
    async fetchPopularCategories() {
      this.fetchingPopularCategories = true;
      const resp = await this.$store.dispatch('analyticsPopularCategories', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count,
        ref: 'publishing-tab'
      });
      if ( resp.organization?.analytics?.topProperties.stale ) {
        this.fetchPopularCategories();
      } else {
        this.fetchingPopularCategories = false;
        this.fetchLocationBasedCategories();
      }
    },
    async fetchLocationBasedCategories() {
      this.fetchinglocationBasedCategories = true;
      const resp = await this.$store.dispatch('analyticsLocationBasedCategories', { 
        query: gql`
          query locationPopularProperties ( $orgId: UUID, $startDate: String, $endDate: String, $platform: String, $count: Int ) {
            organization ( id: $orgId ) {
              id
              analytics {
                ${this.locationBasedCategoriesQuery}
              }
            }
          }`,
        input: {
          orgId: this.orgId,
          platform: this.platform,
          startDate: this.startDate,
          endDate: this.endDate,
          count: this.count,
          ref: 'publishing-tab'
        }
      });
      let refetch = false;
      const countriesData = Object.values( resp?.organization?.analytics ) || [];
      for ( let i = 0; i < countriesData.length; i++) {
        if ( countriesData[i].stale ) {
          refetch = true;
          break;
        }
      }
      if ( refetch ) {
        this.fetchLocationBasedCategories();
      } else {
        this.fetchinglocationBasedCategories = false;
      }
    }
  },
  mounted() {
    this.fetchPopularCategories();
  },
  watch: {
    count () {
      this.fetchPopularCategories();
    },
    location () {
      this.fetchPopularCategories();
    },
    platform () {
      this.fetchPopularCategories();
    },
    startDate () {
      this.fetchPopularCategories();
    },
    endDate () {
      this.fetchPopularCategories();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>