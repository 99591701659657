import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: { 
    height: {
      type: Number,
      default: 200
    },
    options: {
      type: Object,
      default: () => ({
        responsive: true, 
        maintainAspectRatio: false,
        defaultFontColor: '#ededed',
        legend: false,
        animation: {
          duration: 1000
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true 
            }
          }],
          yAxes: [{
            display: true,
            yAxisID: 'y-axis-1',
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }],
          events: ['click'],
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ': ';
                }
                label += Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
              }
            }
          }
        }
      })
    }
  },
  data () {
    return {
      gradient: null,
      gradient2: null
    }
  },
  mounted () {
    //this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    //this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

    //this.gradient.addColorStop(0, 'rgba(186, 2, 255, 1)')
    //this.gradient.addColorStop(0.8, 'rgba(255, 0, 0, 0.25)');
    //this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');
    //this.gradient.addColorStop(1, 'rgba(186, 2, 255, 1)')
    
    /*
    //this.gradient2.addColorStop(0, 'rgba(64, 151, 255, 0.9)')
    this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)')
    this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)');
    */

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  }
}
