<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularUniqueViews', { count }) }}</h3>
          </div>
          <div v-if="fetchingVodUniqueViews" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <bar-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function(value) {
                      return getAbbrv(value);
                    }
                  }
                }]
              }
            }" 
            :chart-data="vodUniqueViews"></bar-chart>
          <p v-if="!fetchingVodUniqueViews && vodUniqueViews.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.TopVODs') }}</h3>
          </div>
          <div v-if="fetchingVodUniqueViews" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="topVodsByUniqueHeader">
              <tr v-if="!fetchingVodUniqueViews && topVodsByUnique.length == 0">
                <td colspan="5" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(vod, i) in topVodsByUnique"
                :key="i"
              >
                <td>{{vod.title}}</td>
                <td class="uk-text-center">{{vod.unique_views}}</td>
                <td class="uk-text-center">{{vod.total_views}}</td>
                <td class="uk-text-center">{{Math.floor(vod.avg_seconds_viewed)}} s</td>
                <td class="uk-text-center">{{Math.floor(vod.total_seconds_viewed)}} s</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.TotalUniqueViews') }}</h3>
          </div>
          <div v-if="fetchingUniqueViews" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="chartOptions" 
            :chart-data="uniqueViews"></line-chart>
          <p v-if="!fetchingUniqueViews && uniqueViews.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularCompletedVideos', { count }) }}</h3>
          </div>
          <div v-if="fetchingUniqueCompletedVods" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="uniqueCompletedVodHeaders">
              <tr v-if="!fetchingUniqueCompletedVods && uniqueCompletedVods.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(vod, i) in uniqueCompletedVods"
                :key="i"
              >
                <td>{{ vod.title }}</td>
                <td class="uk-text-center">{{ vod.unique_views }}</td>
                <td class="uk-text-center">{{ Math.ceil(vod.avg_percent_completion * 100) }}%</td>
                <td class="uk-text-center">{{ vod.total_views }}</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import BarChart from '@/components/charts/BarChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import { getTwoCharacterAbbreviation } from '@/components/util/Strings';

export default {
  name: 'VodDataTab',
  components: {
    LineChart,
    BarChart,
    BaseTable
  },
  props: {
    count: { type: Number, default: 10 },
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null },
    grouping: { type: String, default: 'MONTHLY' },
    location: { type: String, default: 'Global' },
    platform: { type: String, default: 'All' }
  },
  data () {
    return {
      uniqueCompletedVodHeaders: [{
        name: this.$t( 'Pages.Analytics.Table.VideoTitle' ), 
        class: 'uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgPercentCompletion' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ),
        class: 'uk-text-center uk-text-middle'
      }],
      topStoriesHeaders: [{
        name: this.$t( 'Pages.Analytics.Table.VideoTitle' ), 
        class: 'uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgPercentCompletion' ),
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ),
        class: 'uk-text-center uk-text-middle'
      }],
      topVodsByUniqueHeader: [{
        name: this.$t( 'Pages.Analytics.Table.VideoTitle' ), 
        class: 'uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgTimeViewed' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalTimeViewed' ), 
        class: 'uk-text-center uk-text-middle'
      }],
      chartOptions: chartsConfig.options,
      fetchingVodUniqueViews: true,
      fetchingUniqueCompletedVods: true,
      fetchingUniqueViews: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analyticsVodDataTab) ? state.venom.org.analyticsVodDataTab : {},
      countries: state => (state.venom.org.analytics?.topCountries?.value) ? state.venom.org.analytics.topCountries.value : {}
    }),
    topCountries() {
      return [ 'Global', ...Object.keys( this.analytics.topCountries.value ) ];
    },
    vodUniqueViews() {
      let platforms = [];
      Object.values( this.analytics.topVodsByUnique.value ).map( p => 
        platforms = new Set([ ...platforms, ...Object.keys( p.views_per_platform ) ])
      );

      const labels = [];
      let labelValues = {};
      Object.values( this.analytics.topVodsByUnique.value ).map( p => {
        labels.push( p.title );
        
        // prepare set of values for each platform
        [ ...platforms ].map( name => {
          const val = p.views_per_platform[name]?.unique || 0;
          labelValues[name] ? labelValues[name].push( val ) : labelValues[name] = [ val ];
        });
      });
      
      // prepare dataset for all the values to be plotted on the chart
      const datasets = [];
      Object.keys( labelValues ).map( (name, key) => {
        datasets.push({
          ...getLabelOptions(key),
          label: name,
          data: labelValues[name]
        });
      });
      
      return {
        labels: labels,
        datasets: datasets
      };
    },
    uniqueCompletedVods() {
      return this.analytics?.topVodsByCompletion?.value || [];
    },
    topVodsByUnique() {
      const vods = this.analytics.topVodsByUnique;
      return vods.value;
    },
    uniqueViews() {
      const labels = Object.keys( this.analytics.uniqueViews.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const uniqueViews = Object.values( this.analytics.uniqueViews.value ).reverse();

      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          label: this.$t( 'Pages.Analytics.Title.UniqueViews' ),
          data: uniqueViews
        }]
      };
    }
  },
  methods: {
    getAbbrv( value ) {
      return getTwoCharacterAbbreviation( value );
    },
    async fetchVodUniqueViews() {
      this.fetchingVodUniqueViews = true;
      const resp = await this.$store.dispatch('analyticsVodUniqueViews', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count,
        ref: 'vod-data-tab'
      });
      if ( resp.organization?.analytics?.topVodsByUnique.stale ) {
        this.fetchVodUniqueViews();
      } else {
        this.fetchingVodUniqueViews = false;
      }
    },
    async fetchUniqueCompletedVods() {
      this.fetchingUniqueCompletedVods = true;
      const resp = await this.$store.dispatch('analyticsUniqueCompletedVods', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count,
        ref: 'vod-data-tab'
      });
      if ( resp.organization?.analytics?.topVodsByCompletion?.stale ) {
        this.fetchUniqueCompletedVods();
      } else {
        this.fetchingUniqueCompletedVods = false;
      }
    },
    async fetchUniqueViews() {
      this.fetchingUniqueViews = true;
      const resp = await this.$store.dispatch('analyticsUniqueViews', { 
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        ref: 'vod-data-tab'
      });
      if ( resp.organization?.analytics?.uniqueViews.stale ) {
        this.fetchUniqueViews();
      } else {
        this.fetchingUniqueViews = false;
      }
    },
    fetchAllVodData() {
      this.fetchVodUniqueViews();
      this.fetchUniqueCompletedVods();
      this.fetchUniqueViews();
    }
  },
  mounted () {
    this.fetchAllVodData();
  },
  watch: {
    count () {
      this.fetchAllVodData();
    },
    location () {
      this.fetchAllVodData();
    },
    platform () {
      this.fetchAllVodData();
    },
    grouping () {
      this.fetchAllVodData();
    },
    startDate () {
      this.fetchAllVodData();
    },
    endDate () {
      this.fetchAllVodData();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>