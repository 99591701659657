var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"uk-child-width-1-1@s",attrs:{"uk-grid":""}},[_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-small uk-card-body uk-height-large"},[_c('div',{staticClass:"uk-flex"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v(_vm._s(_vm.$t('Pages.Analytics.Title.PopularCategories')))])]),(_vm.fetchingPopularCategories)?_c('div',{staticClass:"uk-position-center"},[_c('span',{attrs:{"uk-spinner":""}})]):_c('bar-chart',{staticClass:"custom-chart",attrs:{"height":230,"options":Object.assign({}, _vm.chartOptions, 
            {legend: { display: false },
            scales: {
              xAxes: [{
                ticks: {
                  callback: function(value) {
                    return _vm.getAbbrv(value);
                  }
                }
              }]
            }}),"chart-data":_vm.popularCategories}}),(!_vm.fetchingPopularCategories && _vm.popularCategories.datasets.length === 0)?_c('p',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('Generic.Labels.NoDataAvailable')))]):_vm._e()],1)])]),_c('div',{staticClass:"uk-child-width-1-1@s",attrs:{"uk-grid":""}},[_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-small uk-card-body uk-height-large"},[_c('div',{staticClass:"uk-flex"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v(_vm._s(_vm.$t('Pages.Analytics.Title.LocationBasedPopularCategories')))])]),(_vm.fetchinglocationBasedCategories)?_c('div',{staticClass:"uk-position-center"},[_c('span',{attrs:{"uk-spinner":""}})]):_c('div',{staticClass:"uk-overflow-auto",staticStyle:{"height":"355px"}},[_c('base-table',{attrs:{"tableHeaders":_vm.locationBasedCategoryHeaders}},[(!_vm.fetchinglocationBasedCategories && _vm.locationBasedCategories.length == 0)?_c('tr',[_c('td',{staticClass:"uk-text-center",attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.$t( 'Generic.Labels.NoRecordsAvailable' )))])]):_vm._l((_vm.locationBasedCategories),function(locView,i){return _c('tr',{key:i},_vm._l((locView),function(val,ii){return _c('td',{key:ii,class:ii === 0 ? '' : 'uk-text-center'},[_vm._v(_vm._s(val))])}),0)})],2)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }