import moment from 'moment';

export const chartsConfig = {
  borderColor: ['#00aaff', '#8d00ff', '#FF9671', '#59981A'],
  pointBackgroundColor: ['#cacaca', '#cacaca', '#cacaca', '#cacaca'],
  pointBorderColor: ['#00aaff', '#8d00ff', '#8a00ff', '#2200ff'],
  backgroundColor: ['rgba( 40, 80, 255, 0.2 )', '#aa00ff20', '#FF967120', '#59981A20'],
  options: {
    legend: {
      position: 'top',
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
  }
};

export const getLabelOptions = ( index = 0 ) => {
  return {
    borderWidth: 1,
    borderColor: chartsConfig.borderColor[index],
    pointRadius: 4,
    pointBackgroundColor: chartsConfig.pointBackgroundColor[index],
    pointBorderColor: chartsConfig.pointBorderColor[index],
    pointBorderWidth: 4,
    pointHoverBorderWidth: 15,
    backgroundColor: chartsConfig.backgroundColor[index],
    fill: 'bottom',
    lineTension: 0.25,
    spanGaps: true,
    barThickness: 20
  };
}

export const getMonths = ( totalMonths ) =>  {
  const months = [];
  for ( let i = 0; i <= totalMonths; i++ ) {
    months.push( moment().subtract(i, 'months').format('MMM\' YY') );
  }
  return months.reverse();
}