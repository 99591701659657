<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularPlatforms') }}</h3>
          </div>
          <div v-if="fetchingPopularPlatforms" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <bar-chart v-else class="custom-chart" :height="230" 
            :options="{ ...chartOptions, legend: { display: false }}"
            :chart-data="popularPlatforms"></bar-chart>
          <p v-if="!fetchingPopularPlatforms && popularPlatforms.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LocationBasedPopularPlatforms') }}</h3>
          </div>
          <div v-if="fetchinglocationBasedPlatforms" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="locationBasedPlatformHeaders">
              <tr v-if="!fetchinglocationBasedPlatforms && locationBasedPlatforms.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(locView, i) in locationBasedPlatforms"
                :key="i"
              >
                <td v-for="(val, ii) in locView" 
                  :key="ii"
                  :class="ii === 0 ? '' : 'uk-text-center'"
                >{{ val }}</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import gql from 'graphql-tag';

export default {
  name: 'PlatformsTab',
  components: {
    BarChart,
    BaseTable
  },
  props: {
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null }
  },
  data () {
    return {
      chartOptions: chartsConfig.options,
      fetchingPopularPlatforms: true,
      fetchinglocationBasedPlatforms: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analyticsPlatformsTab) ? state.venom.org.analyticsPlatformsTab : {},
      countries: state => (state.venom.org.analytics?.topCountries?.value) ? state.venom.org.analytics.topCountries.value : {}
    }),
    topCountries() {
      return [ 'Global', ...Object.keys( this.countries ) ];
    },
    popularPlatforms() {
      const labels = Object.keys( this.analytics.forAllPlatforms.value );
      let values = Object.values( this.analytics.forAllPlatforms.value );

      let datasets = [{
        ...getLabelOptions(),
        data: values
      }];
      const totalBlank = values.filter(v => v === 0 );
      if ( values.length === totalBlank.length ) {
        datasets = [];
      }
      return {
        labels: labels,
        datasets: datasets
      };
    },
    locationBasedPlatformsQuery() {
      const locations = this.topCountries;
      let locationQueries = '';
      for ( let index = 0; index < locations.length; index++ ) {
        locationQueries = locationQueries.concat(
          `${locations[index].replace(' ', '__')}: forAllPlatforms (startDate: $startDate, endDate: $endDate, location: "${locations[index]}" ) ` );
      }
      return locationQueries;
    },
    locationBasedPlatformHeaders() {
      const locationsData = this.analytics.locationBasedPlatforms;
      const platforms = [];
      Object.values( locationsData ).map( d => 
        Object.keys( d.value ).map( p => platforms.includes( p ) ? '' : platforms.push( p ) )
      );

      const headers = [{
        name: this.$t( 'Pages.Analytics.Table.Segment' ), 
        class: 'uk-width-small uk-text-middle'
      }];
      platforms.map( c => headers.push({
        name: c.replace('__', ' '),
        class: 'uk-text-center uk-text-middle'
      }));

      return headers;
    },
    locationBasedPlatforms() {
      const locationData = this.analytics.locationBasedPlatforms;
      const headers = this.locationBasedPlatformHeaders;
      const platforms = [];
      for( const country in locationData) {
        const row = [ country.replace('__', ' ') ];
        headers.map( (p, i) => {
          if ( i == 0 ) return;
          row.push( locationData[country]?.value[p.name] === 'undefined' ? 0 : locationData[country].value[p.name] )
        });
        platforms.push(row);
      }
      return platforms;
    }
  },
  methods: {
    async fetchPopularPlatforms() {
      this.fetchingPopularPlatforms = true;
      const resp = await this.$store.dispatch('analyticsPopularPlatforms', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        ref: 'platforms-tab'
      });
      if ( resp.organization?.analytics?.forAllPlatforms.stale ) {
        this.fetchPopularPlatforms();
      } else {
        this.fetchingPopularPlatforms = false;
        this.fetchLocationBasedPlatforms();
      }
    },
    async fetchLocationBasedPlatforms() {
      this.fetchinglocationBasedPlatforms = true;
      const resp = await this.$store.dispatch('analyticsLocationBasedPlatforms', { 
        query: gql`
          query locationPopularPlatforms ( $orgId: UUID, $startDate: String, $endDate: String,  ) {
            organization ( id: $orgId ) {
              id
              analytics {
                ${this.locationBasedPlatformsQuery}
              }
            }
          }`,
        input: {
          orgId: this.orgId,
          startDate: this.startDate,
          endDate: this.endDate,
          ref: 'platforms-tab'
        }
      });
      let refetch = false;
      const countriesData = Object.values( resp.organization?.analytics );
      for ( let i = 0; i < countriesData.length; i++) {
        if ( countriesData[i].stale ) {
          refetch = true;
          break;
        }
      }
      if ( refetch ) {
        this.fetchLocationBasedPlatforms();
      } else {
        this.fetchinglocationBasedPlatforms = false;
      }
    }
  },
  mounted() {
    this.fetchPopularPlatforms();
  },
  watch: {
    startDate () {
      this.fetchPopularPlatforms();
    },
    endDate () {
      this.fetchPopularPlatforms();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>