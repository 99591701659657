<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.UniqueActiveVisitors') }}</h3>
          </div>
          <div v-if="fetchingUniqueAndActiveUsers" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="130" :options="chartOptions" :chart-data="uniqeAndActiveUsersData"></line-chart>
          <p v-if="!fetchingUniqueAndActiveUsers && uniqeAndActiveUsersData.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-expand@l" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LocationBasedUniqueViews') }}</h3>
          </div>
          <div v-if="fetchinglocationBasedUniqueViews" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="locationBasedViewsHeaders">
              <tr v-if="!fetchinglocationBasedUniqueViews && locationBasedViews.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(locView, i) in locationBasedViews"
                :key="i"
              >
                <td>{{ locView['date'] }}</td>
                <td class="uk-text-center"
                  v-for="(loc, j) in topCountries"
                  :key="j">
                  {{ locView[loc] }}
                </td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LocationBasedActiveUsers') }}</h3>
          </div>
          <div v-if="fetchinglocationBasedActiveUsers" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="locationBasedViewsHeaders">
              <tr v-if="!fetchinglocationBasedActiveUsers && locationBasedUsers.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(locView, i) in locationBasedUsers"
                :key="i"
              >
                <td>{{ locView['date'] }}</td>
                <td class="uk-text-center"
                  v-for="(loc, j) in topCountries"
                  :key="j">
                  {{ locView[loc] }}
                </td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import gql from 'graphql-tag';

export default {
  name: 'VisitorsDataTab',
  components: {
    LineChart,
    BaseTable
  },
  props: {
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null },
    grouping: { type: String, default: 'MONTHLY' },
    location: { type: String, default: 'Global' },
    platform: { type: String, default: 'All' }
  },
  data () {
    return {
      chartOptions: chartsConfig.options,
      fetchingUniqueAndActiveUsers: true,
      fetchinglocationBasedUniqueViews: true,
      fetchinglocationBasedActiveUsers: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analyticsVisitorsDataTab) ? state.venom.org.analyticsVisitorsDataTab : {},
      countries: state => (state.venom.org.analytics?.topCountries?.value) ? state.venom.org.analytics.topCountries.value : {}
    }),
    uniqeAndActiveUsersData() {
      const labels = Object.keys( this.analytics?.totalUniqueVisitors.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const uniqueVisitors = Object.values( this.analytics?.totalUniqueVisitors.value ).reverse();
      const activeUsers = Object.values( this.analytics?.activeUsers.value ).reverse();

      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(0),
          label: this.$t( 'Pages.Analytics.Title.UniqueVisitors' ),
          data: uniqueVisitors
        }, {
          ...getLabelOptions(1),
          label: this.$t( 'Pages.Analytics.Title.ActiveUsers' ),
          data: activeUsers
        }]
      };
    },
    topCountries() {
      return [ 'Global', ...Object.keys( this.countries ) ];
    },
    locationBasedUniqueVisitorsQuery() {
      const locations = this.topCountries;
      let locationQueries = '';
      for ( let index = 0; index < locations.length; index++ ) {
        locationQueries = locationQueries.concat(
          `${locations[index].replace(' ', '__')}: totalUniqueVisitors (startDate: $startDate, endDate: $endDate, grouping: $grouping, location: "${locations[index]}", platform: $platform ) ` );
      }
      return locationQueries;
    },
    locationBasedActiveUsersQuery() {
      const locations = this.topCountries;
      let locationQueries = '';
      for ( let index = 0; index < locations.length; index++ ) {
        locationQueries = locationQueries.concat(
          `${locations[index].replace(' ', '__')}: activeUsers (startDate: $startDate, endDate: $endDate, grouping: $grouping, location: "${locations[index]}", platform: $platform ) ` );
      }
      return locationQueries;
    },
    locationBasedViewsHeaders() {
      const headers = [{
        name: this.$t( 'Pages.Analytics.Table.Date' ), 
        class: 'uk-text-middle'
      }];
      this.topCountries.map( c => headers.push({
        name: c, 
        class: 'uk-text-center uk-text-middle'
      }));
      
      return headers;
    },
    locationBasedViews() {
      const views = this.analytics.locationBasedViews;
      const data = [];
      Object.keys( views['Global'].value ).map( date => {
        const values = { date: moment( date ).format('MMM\' YY') };
        this.topCountries.map( l => values[ l ] = views[ l.replace(' ', '__') ]?.value[ date ] || 0 );
        data.push( values );
      } );
      return data;
    },
    locationBasedUsers() {
      const views = this.analytics.locationBasedActiveUsers;
      const data = [];
      Object.keys( views['Global'].value ).map( date => {
        const values = { date: moment( date ).format('MMM\' YY') };
        this.topCountries.map( l => values[ l ] = views[ l.replace(' ', '__') ]?.value[ date ] || 0 );
        data.push( values );
      } );
      return data;
    }
  },
  methods: {
    async fetchUniqueAndActiveUsers() {
      this.fetchingUniqueAndActiveUsers = true;
      const resp = await this.$store.dispatch('analyticsUniqueAndActiveUsers', { 
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        
        ref: 'visitors-data-tab'
      });
      if ( resp.organization?.analytics.totalUniqueVisitors.stale || resp.organization?.analytics.activeUsers.stale ) {
        this.fetchUniqueAndActiveUsers();
      } else {
        this.fetchingUniqueAndActiveUsers = false;
        this.locationBasedUniqueViews();
        this.locationBasedActiveUsers();
      }
    },
    async locationBasedUniqueViews() {
      this.fetchinglocationBasedUniqueViews = true;
      const resp = await this.$store.dispatch('analyticsLocationBasedViews', { 
        query: gql`
          query locationBasedUniqueViews ( $orgId: UUID, $grouping: String, $startDate: String, $endDate: String, $platform: String ) {
            organization ( id: $orgId ) {
              id
              analytics {
                ${this.locationBasedUniqueVisitorsQuery}
              }
            }
          }`,
        input: {
          orgId: this.orgId,
          grouping: this.grouping,
          startDate: this.startDate,
          endDate: this.endDate,
          platform: this.platform,
          ref: 'visitors-data-tab'
        }
      });
      let isStale = false;
      Object.values( resp.organization?.analytics ).forEach( r => {
        if ( r.stale ) isStale = true;
      });
      if ( isStale ) {
        this.locationBasedUniqueViews();
      } else {
        this.fetchinglocationBasedUniqueViews = false;
      }
    },
    async locationBasedActiveUsers() {
      this.fetchinglocationBasedActiveUsers = true;
      const resp = await this.$store.dispatch('analyticsLocationBasedActiveUsers', { 
        query: gql`
          query locationBasedActiveUsers ( $orgId: UUID, $grouping: String, $startDate: String, $endDate: String, $platform: String ) {
            organization ( id: $orgId ) {
              id
              analytics {
                ${this.locationBasedActiveUsersQuery}
              }
            }
          }`,
        input: {
          orgId: this.orgId,
          grouping: this.grouping,
          startDate: this.startDate,
          endDate: this.endDate,
          platform: this.platform,
          ref: 'visitors-data-tab'
        }
      });
      let isStale = false;
      Object.values( resp?.organization?.analytics ).forEach( r => {
        if ( r.stale ) isStale = true;
      });
      if ( isStale ) {
        this.locationBasedActiveUsers();
      } else {
        this.fetchinglocationBasedActiveUsers = false;
      }
    }
  },
  mounted() {
    this.fetchUniqueAndActiveUsers();
  },
   watch: {
    location () {
      this.fetchUniqueAndActiveUsers();
    },
    platform () {
      this.fetchUniqueAndActiveUsers();
    },
    grouping () {
      this.fetchUniqueAndActiveUsers();
    },
    startDate () {
      this.fetchUniqueAndActiveUsers();
    },
    endDate () {
      this.fetchUniqueAndActiveUsers();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>