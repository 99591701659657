<template>
  <div class="">
    <table :class="tableClass">
      <thead>
        <tr>
          <th 
            v-for="(th, i) in tableHeaders"
            :key="i"
            :class="th.class"
            :colspan="th.colspan"
          >
            {{ th.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
      <tfoot>
      </tfoot>
    </table>
    <!-- TBD 
    <ul class="uk-pagination uk-flex-center" uk-margin>
      <li><a href="#"><span uk-pagination-previous></span></a></li>
      <li
        v-for="page in pageCount" 
        :key="page"
      >
        <a href="#">{{ page }}</a>
      </li>
      <li><a href="#"><span uk-pagination-next></span></a></li>
    </ul>
    -->
  </div>
</template>

<script>
export default {
  name: "BaseTable",
  props: {
    tableHeaders: { 
      type: Array,
      required: false
    },
    pageCount: {
      type: Number,
      default: 1
    },
    totalCount: {
      type: Number,
      default: 0
    },
    removeBorderBottom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableClass() {
      let classes = 'uk-table uk-table-small uk-table-striped uk-table-hover uk-table-divider uk-table-middle uk-table-responsive uk-text-left';
      if ( this.removeBorderBottom ) {
        classes += ' uk-margin-remove-bottom';
      }
      return classes;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
