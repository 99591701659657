<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.PopularUniqueViewsLiveStreams', { count }) }}</h3>
          </div>
          <div v-if="fetchingUniqueViewsLiveStreams" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <line-chart v-else class="custom-chart" :height="230" 
            :options="{
              ...chartOptions,
              legend: { display: false }
            }" 
            :chart-data="uniqueViewsLiveStreams"></line-chart>
          <p v-if="!fetchingUniqueViewsLiveStreams && uniqueViewsLiveStreams.datasets.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.TopLiveStreams') }}</h3>
          </div>
          <div v-if="fetchingTopLiveStreams" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="topLiveStreamsHeaders">
              <tr v-if="!fetchingTopLiveStreams && topLiveStreams.length == 0">
                <td colspan="5" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(stream, i) in topLiveStreams"
                :key="i"
              >
                <td>{{stream.title}}</td>
                <td class="uk-text-center">{{stream.unique_views}}</td>
                <td class="uk-text-center">{{stream.total_views}}</td>
                <td class="uk-text-center">{{Math.floor(stream.avg_seconds_viewed)}} s</td>
                <td class="uk-text-center">{{Math.floor(stream.total_seconds_viewed)}} s</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import { getTwoCharacterAbbreviation } from '@/components/util/Strings';

export default {
  name: 'LiveStreamsTab',
  components: {
    LineChart,
    BaseTable
  },
  props: {
    count: { type: Number, default: 10 },
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null },
    grouping: { type: String, default: 'MONTHLY' },
    location: { type: String, default: 'Global' },
    platform: { type: String, default: 'All' }
  },
  data () {
    return {
      topLiveStreamsHeaders: [{
        name: this.$t( 'Pages.Analytics.Table.LiveStreamTitle' ), 
        class: 'uk-width-large uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.UniqueViews' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalViews' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.AvgTimeViewed' ), 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: this.$t( 'Pages.Analytics.Table.TotalTimeViewed' ), 
        class: 'uk-text-center uk-text-middle'
      }],
      chartOptions: chartsConfig.options,
      fetchingUniqueViewsLiveStreams: true,
      fetchingTopLiveStreams: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org?.analyticsLiveStreamsTab) ? state.venom.org.analyticsLiveStreamsTab : {}
    }),
    uniqueViewsLiveStreams() {
      const labels = Object.keys( this.analytics.liveStreams.value ).reverse().map( t => moment(t).format('MMM\' YY'));
      const values = Object.values( this.analytics.liveStreams.value ).reverse();
      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(),
          data: values
        }]
      };
    },
    topLiveStreams() {
      return this.analytics?.topLiveStreams?.value || [];
    }
  },
  methods: {
    getAbbrv( value ) {
      return getTwoCharacterAbbreviation( value );
    },
    async fetchUniqueViewsLiveStreams() {
      this.fetchingUniqueViewsLiveStreams = true;
      const resp = await this.$store.dispatch('analyticsLiveStreams', { 
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count,
        ref: 'live-streams-tab'
      });
      if ( resp.organization?.analytics.liveStreams.stale ) {
        this.fetchUniqueViewsLiveStreams();
      } else {
        this.fetchingUniqueViewsLiveStreams = false;
      }
    },
    async fetchTopLiveStreams() {
      this.fetchingTopLiveStreams = true;
      const resp = await this.$store.dispatch('analyticsTopLiveStreams', { 
        orgId: this.orgId,
        grouping: this.grouping,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        count: this.count,
        ref: 'live-streams-tab'
      });
      if ( resp.organization?.analytics.topLiveStreams.stale ) {
        this.fetchTopLiveStreams();
      } else {
        this.fetchingTopLiveStreams = false;
      }
    },
    fetchAllStreamData() {
      this.fetchUniqueViewsLiveStreams();
      this.fetchTopLiveStreams();
    }
  },
  mounted() {
    this.fetchAllStreamData();
  },
  watch: {
    count () {
      this.fetchAllStreamData();
    },
    location () {
      this.fetchAllStreamData();
    },
    platform () {
      this.fetchAllStreamData();
    },
    grouping () {
      this.fetchAllStreamData();
    },
    startDate () {
      this.fetchAllStreamData();
    },
    endDate () {
      this.fetchAllStreamData();
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>