<template>
  <div>
    <div uk-grid>
      <div class="uk-width-1-2@s">
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.ActiveUsersCount') }}</h3>
          </div>
          <div v-if="fetchingActiveUsersCount" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-position-center">
            <h1>{{ activeUsersCount }}</h1>
            <a class="uk-button uk-button-primary" :href="xrAnalyticsURL" target="_blank">{{ $t('Pages.Analytics.Title.NewXREcosystemAnalytics') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dtXrAnalyticsURL } from '@/utils/defaultValues';

export default {
  name: 'XrEcosystemTab',
  components: {
  },
  data () {
    return {
      orgId: this.$route.params.id,
      fetchingActiveUsersCount: true
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analytics) ? state.venom.org.analytics : {}
    }),
    xrAnalyticsURL() {
      return dtXrAnalyticsURL;
    },
    activeUsersCount() {
      return this.analytics.xrActiveUserCount.value || 0;
    }
  },
  methods: {
    async fetchActiveUsers() {
      this.fetchingActiveUsersCount = true;
      const resp = await this.$store.dispatch('analyticsXrActiveUsersCount', { 
        orgId: this.orgId
      });
      if ( resp.organization?.analytics?.xrActiveUserCount.stale ) {
        this.fetchActiveUsers();
      } else {
        this.fetchingActiveUsersCount = false;
      }
    }
  },
  mounted() {
    this.fetchActiveUsers();
  }
}
</script>