export const dtId = 'd7cc791b-3a2c-42ef-810e-023668213300';

export const orangeId = '82babe09-186d-4a54-b64c-42a2e62a7fc7';

export const dtXrAnalyticsURL = 'https://analytics.iconicengine.com/dashboard/';

export const ApiResponseTimesFilterType = {
  propertyApi: 'api/v1.1/property/4cb84b7a-2dcb-4722-b0f1-a72e1abc505d',
  collectionsApi: 'api/v1.1/collection/2d6e9f49-bffc-4886-b478-0c5bf2f795f3?without=meta&list_type=videos_streams_ads_stories',
  contentApi: 'api/v1.1/content/78685b04-0a89-4975-a125-4c0195972057?without=meta',
  applicationsApi: 'api/v1.1/application/0ae23bd4-e6ca-421a-a024-ebeb605da336'
};