<template>
  <div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.AvgViewAppTime') }}</h3>
          </div>
          <div v-if="fetchingViewAndAppTime" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <bar-chart v-else class="custom-chart" :height="230" :options="chartOptions" :chart-data="viewAndAppTimeData"></bar-chart>
          <p v-if="!fetchingViewAndAppTime && viewAndAppTimeData.labels.length === 0" class="no-data">{{ $t('Generic.Labels.NoDataAvailable') }}</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LocationBasedViewTime') }}</h3>
          </div>
          <div v-if="fetchinglocationBasedViewTime" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="locationBasedTimeHeaders">
              <tr v-if="!fetchinglocationBasedViewTime && locationBasedViewTimes.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(locView, i) in locationBasedViewTimes"
                :key="i"
              >
                <td>{{ locView[0].replace('__', ' ') }}</td>
                <td class="uk-text-center">{{ locView[1] }}</td>
                <td class="uk-text-center">{{ locView[2] }}</td>
                <td class="uk-text-center">{{ locView[3] }}</td>
                <td class="uk-text-center">{{ locView[4] }}</td>
                <td class="uk-text-center">{{ locView[5] }}</td>
                <td class="uk-text-center">{{ locView[6] }}</td>
                <td class="uk-text-center">{{ locView[7] }}</td>
                <td class="uk-text-center">{{ locView[8] }}</td>
                <td class="uk-text-center">{{ locView[9] }}</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-small uk-card-body uk-height-large">
          <div class="uk-flex">
            <h3 class="uk-card-title">{{ $t('Pages.Analytics.Title.LocationBasedAppTime') }}</h3>
          </div>
          <div v-if="fetchinglocationBasedAppTime" class="uk-position-center">
            <span uk-spinner></span>
          </div>
          <div v-else class="uk-overflow-auto" style="height: 355px">
            <base-table :tableHeaders="locationBasedTimeHeaders">
              <tr v-if="!fetchinglocationBasedAppTime && locationBasedAppTimes.length == 0">
                <td colspan="4" class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</td>
              </tr>
              <tr 
                v-else
                v-for="(locView, i) in locationBasedAppTimes"
                :key="i"
              >
                <td>{{ locView[0].replace('__', ' ') }}</td>
                <td class="uk-text-center">{{ locView[1] }}</td>
                <td class="uk-text-center">{{ locView[2] }}</td>
                <td class="uk-text-center">{{ locView[3] }}</td>
                <td class="uk-text-center">{{ locView[4] }}</td>
                <td class="uk-text-center">{{ locView[5] }}</td>
                <td class="uk-text-center">{{ locView[6] }}</td>
                <td class="uk-text-center">{{ locView[7] }}</td>
                <td class="uk-text-center">{{ locView[8] }}</td>
                <td class="uk-text-center">{{ locView[9] }}</td>
              </tr>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import BaseTable from '@/components/tables/BaseTable.vue';
import { mapState } from 'vuex';
import { chartsConfig, getLabelOptions } from '@/utils/charts';
import gql from 'graphql-tag';

export default {
  name: 'TimeDataTab',
  components: {
    BarChart,
    BaseTable
  },
  props: {
    startDate: { type: [ Object, Date ], default: null },
    endDate: { type: [ Object, Date ], default: null },
    location: { type: String, default: 'Global' },
    platform: { type: String, default: 'All' }
  },
  data () {
    return {
      chartOptions: chartsConfig.options,
      fetchingViewAndAppTime: true,
      fetchinglocationBasedViewTime: true,
      fetchinglocationBasedAppTime: true,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      analytics: state => (state.venom.org.analyticsTimeDataTab) ? state.venom.org.analyticsTimeDataTab : {},
      countries: state => (state.venom.org.analytics?.topCountries?.value) ? state.venom.org.analytics.topCountries.value : {}
    }),
    topCountries() {
      return [ 'Global', ...Object.keys( this.countries ) ];
    },
    viewAndAppTimeData() {
      const labels = Object.keys( this.analytics.viewTime.value );
      const viewTime = Object.values( this.analytics.viewTime.value );
      const appTime = Object.values( this.analytics.sessionDuration.value );

      return {
        labels: labels,
        datasets: [{
          ...getLabelOptions(0),
          label: this.$t( 'Pages.Analytics.Title.AvgViewTime' ),
          data: viewTime
        }, {
          ...getLabelOptions(1),
          label: this.$t( 'Pages.Analytics.Title.AppTime' ),
          data: appTime
        }]
      };
    },
    locationBasedTimeHeaders() {
      const headers = [{
        name: this.$t( 'Pages.Analytics.Table.Segment' ), 
        class: 'uk-text-middle'
      }, {
        name: '0-3S', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '3-10S', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '10-30S', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '30-60S', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '1-3M', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '3-10M', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '10-30M', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '30-60M', 
        class: 'uk-text-center uk-text-middle'
      }, {
        name: '1HR+', 
        class: 'uk-text-center uk-text-middle'
      }];
      
      return headers;
    },
    locationBasedViewTimeQuery() {
      const locations = this.topCountries;
      let locationQueries = '';
      for ( let index = 0; index < locations.length; index++ ) {
        locationQueries = locationQueries.concat(
          `${locations[index].replace(' ', '__')}: viewTime (startDate: $startDate, endDate: $endDate, grouping: $grouping, location: "${locations[index]}", platform: $platform ) ` );
      }
      return locationQueries;
    },
    locationBasedViewTimes() {
      const times = this.analytics.locationBasedViewTimes;
      const countries = Object.keys( times );
      const data = [];
      countries.map( c => {
        const dataItem = [ c ];
        const durations = {
          '0-3s': 0,
          '3-10s': 0,
          '10-30s': 0,
          '30-60s': 0,
          '1-3m': 0,
          '3-10m': 0,
          '10-30m': 0,
          '30-60m': 0,
          '1hr+': 0
        };
        Object.values( times[ c ].value ).map( timeSeries => {
          Object.keys( timeSeries ).map( t => durations[t] += timeSeries[t] );
        });
        data.push( [...dataItem, ...Object.values( durations ) ] );
      });
      return data;
    },
    locationBasedAppTimeQuery() {
      const locations = this.topCountries;
      let locationQueries = '';
      for ( let index = 0; index < locations.length; index++ ) {
        locationQueries = locationQueries.concat(
          `${locations[index].replace(' ', '__')}: sessionDuration (startDate: $startDate, endDate: $endDate, grouping: $grouping, location: "${locations[index]}", platform: $platform ) ` );
      }
      return locationQueries;
    },
    locationBasedAppTimes() {
      const times = this.analytics.locationBasedAppTimes;
      const countries = Object.keys( times );
      const data = [];
      countries.map( c => {
        const dataItem = [ c ];
        const durations = {
          '0-3s': 0,
          '3-10s': 0,
          '10-30s': 0,
          '30-60s': 0,
          '1-3m': 0,
          '3-10m': 0,
          '10-30m': 0,
          '30-60m': 0,
          '1hr+': 0
        };
        Object.values( times[ c ].value ).map( timeSeries => {
          Object.keys( timeSeries ).map( t => durations[t] += timeSeries[t] );
        });
        data.push( [...dataItem, ...Object.values( durations ) ] );
      });
      return data;
    }
  },
  methods: {
    async fetchViewAndAppData() {
      this.fetchingViewAndAppTime = true;
      const resp = await this.$store.dispatch('analyticsAvgViewAndAppTime', { 
        orgId: this.orgId,
        startDate: this.startDate,
        endDate: this.endDate,
        location: this.location,
        platform: this.platform,
        ref: 'time-data-tab'
      });
      if ( resp.organization?.analytics?.viewTime.stale || resp.organization?.analytics?.sessionDuration.stale ) {
        this.fetchViewAndAppData();
      } else {
        this.fetchingViewAndAppTime = false;
        this.fetchLocationBasedViewTimes();
        this.fetchLocationBasedAppTimes();
      }
    },
    async fetchLocationBasedViewTimes() {
      this.fetchinglocationBasedViewTime = true;
      const resp = await this.$store.dispatch('analyticsLocationBasedViewTimes', { 
        query: gql`
          query locationBasedViewTime ( $orgId: UUID, $grouping: String, $startDate: String, $endDate: String, $platform: String ) {
            organization ( id: $orgId ) {
              id
              analytics {
                ${this.locationBasedViewTimeQuery}
              }
            }
          }`,
        input: {
          orgId: this.orgId,
          grouping: this.grouping,
          startDate: this.startDate,
          endDate: this.endDate,
          platform: this.platform,
          ref: 'time-data-tab'
        }
      });
      let refetch = false;
      const countriesData = Object.values( resp.organization?.analytics );
      for ( let i = 0; i < countriesData.length; i++) {
        if ( countriesData[i].stale ) {
          refetch = true;
          break;
        }
      }
      if ( refetch ) {
        this.fetchLocationBasedViewTimes();
      } else {
        this.fetchinglocationBasedViewTime = false;
      }
    },
    async fetchLocationBasedAppTimes() {
      this.fetchinglocationBasedAppTime = true;
      const resp = await this.$store.dispatch('analyticsLocationBasedAppTimes', { 
        query: gql`
          query locationBasedAppTime ( $orgId: UUID, $grouping: String, $startDate: String, $endDate: String, $platform: String ) {
            organization ( id: $orgId ) {
              id
              analytics {
                ${this.locationBasedAppTimeQuery}
              }
            }
          }`,
        input: {
          orgId: this.orgId,
          grouping: this.grouping,
          startDate: this.startDate,
          endDate: this.endDate,
          platform: this.platform,
          ref: 'time-data-tab'
        }
      });
      let refetch = false;
      const countriesData = Object.values( resp.organization.analytics );
      for ( let i = 0; i < countriesData.length; i++) {
        if ( countriesData[i].stale ) {
          refetch = true;
          break;
        }
      }
      if ( refetch ) {
        this.fetchLocationBasedAppTimes();
      } else {
        this.fetchinglocationBasedAppTime = false;
      }
    }
  },
  mounted() {
    this.fetchViewAndAppData();
  },
   watch: {
    location () {
      this.fetchViewAndAppData();
    },
    platform () {
      this.fetchViewAndAppData();
    },
    startDate () {
      this.fetchViewAndAppData();
    },
    endDate () {
      this.fetchViewAndAppData();
    },
    grouping () {
      this.fetchViewAndAppData();
    }
   }
}
</script>

<style lang="scss">
.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
</style>
