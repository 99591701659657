import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: { 
    height: {
      type: Number,
      default: 200
    },
    options: {
      type: Object,
      default: () => ({
        responsive: true, 
        maintainAspectRatio: false,
        defaultFontColor: '#ededed',
        legend: false,
        animation: {
          duration: 1000
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true 
            }
          }],
          yAxes: [{
            display: true,
            yAxisID: 'y-axis-1',
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }],
          events: ['click'],
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ': ';
                }
                label += Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
              }
            }
          }
        }
      })
    }
  },
  data () {
    return {
      gradient: null,
      gradient2: null
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
