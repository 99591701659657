var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"uk-child-width-1-1@s",attrs:{"uk-grid":""}},[_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-small uk-card-body uk-height-large"},[_c('div',{staticClass:"uk-flex"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v(_vm._s(_vm.$t('Pages.Analytics.Title.PropertyApiExecutionTime')))])]),(_vm.fetchingPropertyApiTimes)?_c('div',{staticClass:"uk-position-center"},[_c('span',{attrs:{"uk-spinner":""}})]):_c('line-chart',{staticClass:"custom-chart",attrs:{"height":230,"options":Object.assign({}, _vm.chartOptions,
            {legend: { display: false },
            scales: {
              xAxes: [{
                ticks: {
                  callback: function(value, index) {
                    return _vm.formattedDate( value, index );
                  }
                }
              }]
            }}),"chart-data":_vm.propertyApiTimes}}),(!_vm.fetchingPropertyApiTimes && _vm.propertyApiTimes.datasets.length === 0)?_c('p',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('Generic.Labels.NoDataAvailable')))]):_vm._e()],1)]),_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-small uk-card-body uk-height-large"},[_c('div',{staticClass:"uk-flex"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v(_vm._s(_vm.$t('Pages.Analytics.Title.CollectionsApiExecutionTime')))])]),(_vm.fetchingCollectionsApiTimes)?_c('div',{staticClass:"uk-position-center"},[_c('span',{attrs:{"uk-spinner":""}})]):_c('line-chart',{staticClass:"custom-chart",attrs:{"height":230,"options":Object.assign({}, _vm.chartOptions,
            {legend: { display: false },
            scales: {
              xAxes: [{
                ticks: {
                  callback: function(value, index) {
                    return _vm.formattedDate( value, index );
                  }
                }
              }]
            }}),"chart-data":_vm.collectionsApiTimes}}),(!_vm.fetchingCollectionsApiTimes && _vm.collectionsApiTimes.datasets.length === 0)?_c('p',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('Generic.Labels.NoDataAvailable')))]):_vm._e()],1)]),_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-small uk-card-body uk-height-large"},[_c('div',{staticClass:"uk-flex"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v(_vm._s(_vm.$t('Pages.Analytics.Title.ContentApiExecutionTime')))])]),(_vm.fetchingContentApiTimes)?_c('div',{staticClass:"uk-position-center"},[_c('span',{attrs:{"uk-spinner":""}})]):_c('line-chart',{staticClass:"custom-chart",attrs:{"height":230,"options":Object.assign({}, _vm.chartOptions,
            {legend: { display: false },
            scales: {
              xAxes: [{
                ticks: {
                  callback: function(value, index) {
                    return _vm.formattedDate( value, index );
                  }
                }
              }]
            }}),"chart-data":_vm.contentApiTimes}}),(!_vm.fetchingContentApiTimes && _vm.contentApiTimes.datasets.length === 0)?_c('p',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('Generic.Labels.NoDataAvailable')))]):_vm._e()],1)]),_c('div',[_c('div',{staticClass:"uk-card uk-card-default uk-card-small uk-card-body uk-height-large"},[_c('div',{staticClass:"uk-flex"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v(_vm._s(_vm.$t('Pages.Analytics.Title.ApplicationApiExecutionTime')))])]),(_vm.fetchingApplicationApiTimes)?_c('div',{staticClass:"uk-position-center"},[_c('span',{attrs:{"uk-spinner":""}})]):_c('line-chart',{staticClass:"custom-chart",attrs:{"height":230,"options":Object.assign({}, _vm.chartOptions,
            {legend: { display: false },
            scales: {
              xAxes: [{
                ticks: {
                  callback: function(value, index) {
                    return _vm.formattedDate( value, index );
                  }
                }
              }]
            }}),"chart-data":_vm.applicationApiTimes}}),(!_vm.fetchingApplicationApiTimes && _vm.applicationApiTimes.datasets.length === 0)?_c('p',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('Generic.Labels.NoDataAvailable')))]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }